<template>
    <div class="activites">
        <!-- <div class="activity" > {{ activity.description }}</div> -->
        <div class="activity" v-for="(activity,index) in activities" :key="index">
            <div class="timeline">
                <div class="timeline__icon">
                    <img src="@/assets/images/tick.png" alt="" width="16">
                </div>
            </div>
            <div class="activity__card" >
                <div class="activity__header">
                    <h1 class="activity__date">{{ activity.created }}</h1>
                    <span class="activity__type" :style="{ background: activityTypeColor(activity.activity_type) }"> 
                        {{ activity.activity_type }} 
                    </span>
                </div>
                <div class="activity__body">
                    <div class="activity__description"> "{{ activity.description }}"</div>
                    <div class="activity__detail">Branch -> {{ activity.branch }}</div>
                    <div class="activity__detail">Action Type -> {{ activity.item_type }}</div>
                    <div class="activity__detail">Reference -> {{ activity.reference }}</div>
                    <div class="activity__detail">User -> {{ activity.user }}</div>
                </div>
                <div class="device">
                    <span class="device__info">
                        Device: {{ activity.device_info.name}}
                    </span>
                    <span class="device__info">
                        OS: {{ activity.device_info.operating_system}}
                    </span>
                    <span class="device__info">
                        OS Version: {{ activity.device_info.operating_system_version}}
                    </span>
                    <span class="device__info">
                        Browser: {{ activity.device_info.browser }}
                    </span>
                    <span class="device__info">
                        Browser Version: {{ activity.device_info.browser_version }}
                    </span>
                    <span class="device__info">
                        IPv4: {{ activity.device_info.ip_address }}
                    </span>
                    
                    
                 </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    props:{
        activities:{ type: Object, required: true}
    },
    
    methods:{
        activityTypeColor(activityType){
            let color = '#2B8AEA'
            if(activityType === 'Create'){
                color = '#67CBB5'
            }else if(activityType === 'Delete'){
                color = '#B74F6F'
            }
            return color
        }
    }
}
</script>

<style>

    /* .activites{
        padding: 2em;
    } */

    .activity{
        color: #272838;
        min-height: 15em;
        display: flex;
    }

    .activity__card{
        flex: 1;

        margin: 1em 0;
        margin-left: 1.5em;
        border-radius: 1em;
        background:white;;

        box-shadow:rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }

    .activity__header{
        display: flex;
        align-items: center;
        padding:1.5em 2em;
        border-bottom: solid 1px rgba(228, 220, 220, .75);
    }


    .activity__date{
        font-size: 1.125em;
        opacity: .75;
        font-weight: bold;
    }

    .activity__type{
        color: #fff;
        background: #67CBB5;
        margin-left: auto;
        padding: .5em 1em;
    }

    .activity__description{
        font-size: 1.25em;
        font-style: italic;
        margin-bottom:1em;
    }

    .activity__detail{
        margin-bottom:.5em;
    }

    .activity__body{
        padding:2em;
    }


    .device{
        display: flex;
        flex-wrap: wrap;
        border-top:solid 1px rgba(228, 220, 220, .75);
        opacity: .5;
        
        
    }

    .device__info{
        
        padding: 1em 2em;
       
        border-right: solid 1px rgba(228, 220, 220, .75);
        
        
    }

    .timeline{
        position: relative;
        /* background:pink; */
    }

    .timeline::before{
        content:'';
        position: absolute;
        top:0;
        bottom:0;

        left:50%;

        display: block;
        width: 2px;
        
        background:#E4DCDC;

        z-index: -1;

    }

    .timeline__icon{
        color: #fff;

        margin-top:2.25em;

        display: flex;
        justify-content: center;
        align-items: center;

        width:40px;
        height: 40px;
        border-radius: 50%;
        background:#2B8AEA;
    }

    


</style>