<template>
	<div class="content" >
		<list-header :loading="loading" />
		<div class="content--loading" v-if="loading">
			<filling-circle color="#057DCD" :size="42" />
		</div>
		<div class="content__list"  v-else-if="error">
			<span>Nothing to Display</span>
		</div>
		<div class="content__list"  v-else>
			<activity-list :activities="activities"/>
			<div class="navigation">
				<button v-if="previous" class="navigation__button" @click="gotoCursor(previous)">Previous</button>
				<button v-if="next" class="navigation__button" @click="gotoCursor(next)">Next</button>
			</div>
			<router-view />
		</div>
	</div>
</template>

<script>
import ListHeader from '@/components/ListHeader.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
import ActivityList from '@/components/Activity/ActivityList'
export default {
	components:{ActivityList,ListHeader,FillingCircle},
    data(){
        return {
            loading:true,
			loadingNext:false,
			previous:null,
			next:null,
            error:false,
        }
    },
	computed:{
		activities(){
			return this.$store.state.activity.activities
		},
        id(){
			return this.$route.params.id
		},

		getBranchId(){	
			return this.$store.state.branchId
		},
    },

	methods:{
		
		gotoCursor(cursor){
			this.loading = true
			this.$store.dispatch({type:'activity/loadActivities',id:this.id,cursor:cursor}).then((response)=>{

		
			if (response.next) this.next = response.next.split('cursor=').pop()
			else this.next = response.next

			if (response.previous) this.previous = response.previous.split('cursor=').pop()
			else this.previous = response.previous

			if(!this.getBranchId && this.id) this.setBranchId()

            this.loading = false
            this.loadingNext = false
			console.log(response , `next ${this.next} | previous ${this.previous}`)
        }).catch((error)=>{

			console.log(error)
			this.loading = false
            this.loadingNext = false

		})
		},
		setBranchId(){
			this.$store.commit({type:'setBranchId',branchId:this.id})
		},
	},


    mounted(){
        this.$store.dispatch({type:'activity/loadActivities',id:this.id,cursor:this.next}).then((response)=>{

			let next  = response.next
			if (next) this.next = next.split('cursor=').pop()
			else this.next = next

			if(!this.getBranchId && this.id) this.setBranchId()

            this.loading = false
            this.loadingNext = false
			console.log(response)
        }).catch((error)=>{

			console.log(error)
			this.loading = false
            this.loadingNext = false

		})
    }
}
</script>
<style scoped>
	.navigation{
		display: flex;
		flex-wrap: wrap;
		justify-content: right;
		padding:2em 1em;
	}

	.navigation__button{
		color:#fff;
		font-weight: bold;
		background: var(--bg-dark);
		border:none;
		/* margin:0 4px; */
		padding:.75em 1.25em;
		margin-left:1em;
		
	}
</style>